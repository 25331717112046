import React from "react";
import LoadingIcon from "../LoadingIcon";
import { Link } from "react-router-dom";

const OffsetButton = ({
    children,
    color = "FrenchRed",
    borderColor = "border-teal-500",
    textColor = "text-white",
    className = "",
    hoverEffect = "hover:bg-teal-700",
    type,
    isLoading = false,
    isDisabled = false,
    onClick = null,
    reversed = false,
    icon = null,
    ...props
}) => {
    if (!className.includes("bg")) {
        if (color === "FrenchRed") {
            className += ` bg-FrenchRed-500  dark:bg-FrenchRed-500 outline-FrenchRed-500 dark:border-FrenchRed-600 hover:bg-opacity-0 dark:hover:bg-opacity-0 hover:text-FrenchRed-500 dark:hover:text-FrenchRed-500 clr-white`;
        } else if (color === "rose") {
            if (reversed) {
                className += ` bg-rose-500 border-rose-500 dark:bg-rose-500 dark:border-rose-500 hover:bg-opacity-100 dark:hover:bg-opacity-100 dark:bg-opacity-0 bg-opacity-0 text-rose-500 dark:hover:text-rose-500 hover:text-slate-50 dark:hover:text-slate-50`;
            } else {
                className += ` bg-rose-500 border-rose-500 dark:bg-rose-500 dark:border-rose-500 hover:bg-opacity-0 dark:hover:bg-opacity-0 dark:bg-opacity-100 bg-opacity-100 hover:text-rose-500 dark:hover:text-rose-500 clr-white`;
            }
        } else if (color === "earthYellow") {
            className += ` bg-earthYellow-300 rounded-none dark:bg-earthYellow-500 outline-earthYellow-300 font-w-bold dark:outline-earthYellow-600 text-earthYellow-950`;
        } else if (color === "blueMonq") {
            className += ` bg-blueMonq-500 dark:bg-blueMonq-500 outline-blueMonq-600 rounded-none font-w-bold dark:outline-blueMonq-600 text-blueMonq-100`;
        }
    }
    return (
        <>
            <Link
                onClick={onClick}
                type={type}
                className={`relative outline outline-offset-4 rounded-xl  hover:outline-offset-0 inline-flex  items-center justify-center px-6 py-3 ${
                    isLoading ? "opacity-50 cursor-not-allowed" : ""
                } ${isDisabled ? "opacity-50 cursor-not-allowed" : ""} smooth ${className}`}
                disabled={isDisabled || isLoading ? true : false}
                {...props}
            >
                {isLoading ? (
                    <LoadingIcon />
                ) : (
                    <>
                        {children}
                        {icon && <img src={icon} alt="Icon" className="ml-2 w-5 h-5" />}
                    </>
                )}
            </Link>
        </>
    );
};

export default OffsetButton;
