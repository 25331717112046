import React, { useContext, useEffect, useState } from "react";

import AuthContext from "../../context/AuthContext";

import "./MainSection.css";
import Container from "../../components/ui/Container";

import OffsetButton from "../../components/ui/Buttons/OffsetButton";
import { ReactComponent as Trophy } from "../../assets/monqez-assets/trophy_2784549.svg";
import { ReactComponent as DashLine1 } from "../../assets/monqez-assets/dashLine.svg";
import { ReactComponent as DashLine2 } from "../../assets/monqez-assets/dash_line2.svg";
import { ReactComponent as DashLine3 } from "../../assets/monqez-assets/dash_line3.svg";
import monqezHeroImg from "../../assets/monqez-assets/monqezHeroImg.svg";

import "./Heroimage.css";

import { ReactComponent as TextDecore } from "../../assets/monqez-assets/scratch.svg";
const MainSection = ({ title = "", id = 0 }) => {
    const { token } = useContext(AuthContext);

    return (
        <>
            <section className="md:h-screen min-h-screen negative-nav-margin relative overflow-hidden">
                {/* <div
                    className="right-0 top-0 bottom-0 h-full w-full z-0 absolute opacity-30 dark:opacity-20 smooth"
                    style={{
                        backgroundImage: "url(" + Pattern + ")",
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                        backgroundRepeat: "repeat-both",
                    }}
                ></div> */}
                <DashLine1 className="absolute hidden md:block right-0 top-5 md:-top-20 " />

                <DashLine3 className="absolute hidden md:block left-0 top-5 md:-top-10 " />
                <Container className="h-full relative z-10 font-fs pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row-reverse flex-col h-full space-y-10 md:space-y-0   ">
                        <div className="md:basis-1/2 px-0 md:px-10 basis-full h-full flex-center-both">
                            <div className="flex md:items-start items-center justify-center flex-col space-y-6 md:space-y-0 font-graphic">
                                <div className="relative">
                                    <div className="relative z-10 ">
                                        <div className="pt-3">
                                            <h1 className="text-5xl relative lg:text-6xl xl:text-8xl clr-text-primary smooth">
                                                <span className="font-lama font-w-bold text-[3.5rem] lg:text-[4.5rem]">
                                                    منصة
                                                    <span className="text-earthYellow-500">
                                                        الــمٌنقذ
                                                    </span>
                                                </span>
                                                <TextDecore className="fill-earthYellow-500 absolute -z-10 -top-5 opacity-50" />
                                            </h1>
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-4 text-center md:text-right text-xl md:text-3xl w-3/4 text-gray-600 dark:text-gray-200 smooth space-y-2 flex-center-both flex-col font-lama">
                                    معاك يابطل من إبتدائي لـ إعدادي و اتأكد إن اول باب لنجاحك من
                                    خلال تأسيس مظبوط ,, هننقذك
                                </div>

                                <div className=" pt-20 md:pb-0  ">
                                    <OffsetButton
                                        color="earthYellow"
                                        className="flex-center-both text-lg gap-4"
                                        to={!token ? `/register` : "/me/user/courses"}
                                    >
                                        <Trophy className="mb-1 w-7 h-full " />
                                        {!token ? "أشترك معانا" : " كورساتي"}
                                    </OffsetButton>
                                </div>
                            </div>
                        </div>
                        <div className="md:basis-1/2 basis-full h-full flex-center-both pt-10 md:pt-0">
                            <div className="flex-center-both max-w-3xl px-4 sm:px-20">
                                {/* <img src={HeroImg} alt={"profile"} className="w-full h-full" /> */}
                                <img src={monqezHeroImg} alt={"profile"} className="" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
