import Container from "../../components/ui/Container";
import React from "react";
import Heading from "../../components/NewUi/heading/Heading";
import Card from "../../components/NewUi/featureCard/Card";
import RetroCardSection from "../../components/ui/RetroCardSection";
import Waves from "../../components/ui/Waves";
// import auth from '../../services/authServices'
const FeatureSection = () => {
    // const token = auth.getToken();

    return (
        <div className="">
            <section className=" min-h-screen md:h-fit relative overflow-hidden flex justify-center items-start md:items-start xl:items-start ">
                <Container className="h-fit relative z-10 pb-10 md:pb-0 space-y-4 flex flex-col gap-5 md:gap-10">
                    <div className="flex flex-col mx-auto items-center justify-center h-fit">
                        <Heading title="ليه المٌنقذ؟" subtitle="هتختارنا ليه ..؟" />
                    </div>
                    <RetroCardSection />
                </Container>
            </section>
            <div className="relative min-h-[30vh] bg-blueMonq-400 dark:bg-blueMonq-900 smooth flex-col">
                <Waves className="fill-primary-container transform -translate-y-1/4 scale-y-[.5] rotate-180 z-10 absolute right-0 top-0 left-0 w-full" />
                <Waves className="fill-primary-container transform translate-y-1/4 scale-y-[.5] z-10 absolute right-0 bottom-0 left-0 w-full" />
            </div>
        </div>
    );
};

export default FeatureSection;
