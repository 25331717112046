import React from "react";
import SubjectCard from "../NewUi/subjectCard/SubjectCard";
import Card from "../NewUi/featureCard/Card";
import Pattern from "../../assets/monqez-assets/bgTeacherPattern.png";
const RetroCardSection = ({ isSubject = false, subjectData }) => {
    // console.log(subjectData);
    return (
        <div className=" bg-slate-100 rounded-lg relative dark:bg-blueMonq-950 smooth w-full py-14 md:py-0 overflow-hidden lg:min-w-[1280px] lg:max-w-[1280px]  min-h-[580px] flex justify-center items-center">
            <div
                className="inset-0 w-full h-full z-0 absolute opacity-40 smooth"
                style={{
                    backgroundImage: "url(" + Pattern + ")",
                    backgroundSize: "contain",
                    backgroundPosition: "center top",
                    backgroundRepeat: "repeat",
                }}
            ></div>
            {!isSubject ? <Card /> : <SubjectCard {...subjectData} />}
        </div>
    );
};

export default RetroCardSection;
