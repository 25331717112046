import React, { useContext, useState } from "react";
import {
    English,
    Arabic,
    France,
    italy,
    germany,
    Math,
    Geo,
    Bio,
    chemistry,
    Brain,
    book,
} from "./Svgs";
import { Link } from "react-router-dom";
import Button from "../../ui/Button";
import AuthContext from "../../../context/AuthContext";
import VariantButton from "../../ui/Buttons/VariantButton";
import { ReactComponent as Ellipse } from "../../../assets/Vip-imagery/Ellipse.svg";
function SubjectCard({ first, sec, third, fourth, fifth, sixth }) {
    // console.log(first);
    const [seeMoreState, setSeeMoreState] = useState({});

    const { token, user } = useContext(AuthContext);

    const toggleSeeMore = (index, set) => {
        setSeeMoreState((prevState) => ({
            ...prevState,
            [`${set}-${index}`]: !prevState[`${set}-${index}`],
        }));
    };

    const cardData1 = [
        {
            year: 5,
            title: "الصف الأول الإعدادي",
            data: fourth,
        },
        {
            year: 6,
            title: "الصف الثاني الإعدادي",

            data: fifth,
        },
        {
            year: 7,
            title: "الصف الثالث الإعدادي",

            data: sixth,
        },
    ];
    const cardData2 = [
        {
            year: 1,
            title: "الصف الأول الثانوي",
            data: first,
        },
        {
            year: 2,
            title: "الصف الثاني الثانوي",

            data: sec,
        },
        {
            year: 3,
            title: "الصف الثالث الثانوي",
            data: third,
        },
    ];

    return (
        <>
            <div className="w-full h-full py-20 flex flex-col space-y-20 relative z-10">
                {/* <div className="w-[1px] h-full md:h-[1px] bg-blueMonq-950 dark:bg-blueMonq-50 absolute top-0 bottom-0 mx-auto md:mx-0 md:left-0 md:right-0 md:top-1/2 " /> */}

                <div className="flex flex-wrap gap-10 justify-evenly">
                    {cardData1.map((card, index) =>
                        !token || user.year === card.year ? (
                            <div key={index} className={`${index == 1 && "mt-0 lg:-mt-5"}`}>
                                <div className="min-h-[400px] w-[300px] relative bg-blueMonq-300 smooth dark:bg-blueMonq-800 border border-solid space-y-5 border-black flex flex-col justify-between  items-center px-2">
                                    <div className="flex flex-col space-y-10">
                                        <Ellipse className="fill-blueMonq-100 dark:fill-blue-600 smooth absolute top-0" />
                                        <div className="">
                                            <h1 className="font-expo text-center text-2xl text-blueMonq-950 smooth dark:text-blueMonq-50 relative z-10">
                                                {card.title}
                                            </h1>
                                        </div>

                                        <div className=" w-full m-auto h-full px-3 py-6 p-3 flex gap-2 justify-evenly items-center flex-col">
                                            {card.data
                                                .slice(
                                                    0,
                                                    seeMoreState[`${"set1"}-${index}`]
                                                        ? card.data.length
                                                        : 3
                                                )
                                                .map((item, index) => {
                                                    return (
                                                        <Link
                                                            to={`/subject/${item[1]}`}
                                                            key={index}
                                                        >
                                                            <p
                                                                className="text-4xl z-10 relative smooth hover:bg-earthYellow-500 hover:text-earthYellow-950 bg-blueMonq-500 text-blue-50 rounded-2xl px-5 py-2 font-h3 font-bold text-center"
                                                                key={index}
                                                            >
                                                                {item[0]}
                                                            </p>
                                                        </Link>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                    <div className="">
                                        <VariantButton
                                            ButtonType="Retro"
                                            className=" bg-earthYellow-200 dark:bg-earthYellow-500 text-earthYellow-950 font-w-bold border border-solid border-black mx-auto"
                                            onClick={() => toggleSeeMore(index, "set1")}
                                        >
                                            {seeMoreState[`${"set1"}-${index}`]
                                                ? "اخفاء"
                                                : "عرض المزيد"}
                                        </VariantButton>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    )}
                </div>
                <div className="flex flex-wrap gap-10 justify-evenly">
                    {cardData2.map((card, index) => (
                        <div key={index} className={`${index == 1 && "mt-0 lg:mt-10"}`}>
                            <div
                                className="min-h-[400px] w-[300px] relative bg-blueMonq-300 smooth dark:bg-blueMonq-800 border border-solid space-y-5 border-black flex flex-col justify-between  items-center px-2
                            "
                            >
                                <div className="flex flex-col space-y-10">
                                    <Ellipse className="fill-blueMonq-100 dark:fill-blue-600 smooth absolute top-0" />
                                    <div className="">
                                        <h1 className="font-expo text-center text-2xl text-blueMonq-950 smooth dark:text-blueMonq-50 relative z-10">
                                            {card.title}
                                        </h1>
                                    </div>

                                    <div className=" w-full m-auto h-full px-3 py-6 p-3 flex gap-2 justify-evenly items-center flex-col">
                                        {card.data
                                            .slice(
                                                0,
                                                seeMoreState[`${"set2"}-${index}`]
                                                    ? card.data.length
                                                    : 3
                                            )
                                            .map((item, index) => {
                                                return (
                                                    <Link to={`/subject/${item[1]}`} key={index}>
                                                        <p
                                                            className="text-4xl z-10 relative smooth hover:bg-earthYellow-500 hover:text-earthYellow-950 bg-blueMonq-500 text-blue-50 rounded-2xl px-5 py-2 font-h3 font-bold text-center"
                                                            key={index}
                                                        >
                                                            {item[0]}
                                                        </p>
                                                    </Link>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div className="">
                                    <VariantButton
                                        ButtonType="Retro"
                                        className=" bg-earthYellow-200 dark:bg-earthYellow-500 text-earthYellow-950 font-w-bold  border border-solid border-black mx-auto"
                                        onClick={() => toggleSeeMore(index, "set2")}
                                    >
                                        {seeMoreState[`${"set2"}-${index}`]
                                            ? "اخفاء"
                                            : "عرض المزيد"}
                                    </VariantButton>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* <div className="w-full h-full flex items-center justify-center">
                <div className="flex items-start justify-center flex-wrap h-full w-full gap-5 md:gap-20 lg:gap-10 xl:gap-5">
                    {!token || user.year === 1 ? (
                        <div className="flex flex-col gap-5">
                            <div className="relative flex h-fit width items-center justify-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="231"
                                    height="101"
                                    viewBox="0 0 231 101"
                                    fill="none"
                                >
                                    <ellipse
                                        cx="115.5"
                                        cy="50.5"
                                        rx="115.5"
                                        ry="50.5"
                                        className="fill-secYellow-600 dark:fill-secYellow-400"
                                    />
                                </svg>
                                <h1 className="absolute right-[20%] md:font-w-bold text-3xl md:text-4xl  ">
                                    الصف الأول الثانوي
                                </h1>
                            </div>

                            <div className=" w-full m-auto sm:w-[400px] h-full px-3 py-6 rounded-3xl bg-primSky-600 dark:bg-primSky-400 p-3 flex gap-2 justify-evenly items-center flex-col">
                                {first.map((item, index) => {
                                    if (index < 3 || isSeeMore1) {
                                        return (
                                            <Link to={`/subject/${item[1]}`} key={index}>
                                                <p
                                                    className="text-4xl smooth hover:bg-[#102542] rounded-2xl px-5 py-2 font-h3 font-bold text-center text-primSky-950"
                                                    key={index}
                                                >
                                                    {item[0]}
                                                </p>
                                            </Link>
                                        );
                                    }
                                })}
                                <Button color="yellow" onClick={() => setIsSeeMore1(!isSeeMore1)}>
                                    {isSeeMore1 ? "اخفاء" : "عرض المزيد"}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}

                    {!token || user.year === 2 ? (
                        <div className="flex flex-col gap-5 mt-0 lg:mt-14">
                            <div className="relative flex h-fit width items-center justify-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="231"
                                    height="101"
                                    viewBox="0 0 231 101"
                                    fill="none"
                                >
                                    <ellipse
                                        cx="115.5"
                                        cy="50.5"
                                        rx="115.5"
                                        ry="50.5"
                                        className="fill-secYellow-600 dark:fill-secYellow-400"
                                    />
                                </svg>
                                <h1 className="absolute right-[20%] md:font-w-bold text-3xl md:text-4xl ">
                                    الصف الثاني الثانوي
                                </h1>
                            </div>
                            <div className=" w-full m-auto sm:w-[400px] h-full px-3 py-6 rounded-3xl bg-primSky-600 dark:bg-primSky-400 p-3 flex gap-2 flex-col items-center justify-evenly">
                                {sec.map((item, index) => {
                                    if (index < 3 || isSeeMore2) {
                                        return (
                                            <Link to={`/subject/${item[1]}`} key={index}>
                                                <p
                                                    className="text-4xl smooth hover:bg-[#102542] rounded-2xl px-5 py-2 font-h3 font-bold text-center text-primSky-950"
                                                    key={index}
                                                >
                                                    {item[0]}
                                                </p>
                                            </Link>
                                        );
                                    }
                                })}
                                <Button color="yellow" onClick={() => setIsSeeMore2(!isSeeMore2)}>
                                    {isSeeMore2 ? "اخفاء" : "عرض المزيد"}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}

                    {!token || user.year == 3 ? (
                        <div className="flex flex-col gap-5 mt-0 lg:mt-24">
                            <div className="relative flex h-fit width items-center justify-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="231"
                                    height="101"
                                    viewBox="0 0 231 101"
                                    fill="none"
                                >
                                    <ellipse
                                        cx="115.5"
                                        cy="50.5"
                                        rx="115.5"
                                        ry="50.5"
                                        className="fill-secYellow-600 dark:fill-secYellow-400"
                                    />
                                </svg>
                                <h1 className="absolute right-[20%] md:font-w-bold text-3xl md:text-4xl ">
                                    الصف الثالث الثانوي
                                </h1>
                            </div>
                            <div className="w-full m-auto sm:min-w-[400px] h-full px-3 py-6 rounded-3xl bg-primSky-600 dark:bg-primSky-400 p-3 flex gap-2 flex-col items-center justify-evenly">
                                {third.map((item, index) => {
                                    if (index < 3 || isSeeMore3) {
                                        return (
                                            <Link to={`/subject/${item[1]}`} key={index}>
                                                <p
                                                    className="text-4xl smooth hover:bg-[#102542] rounded-2xl px-5 py-2 font-h3 font-bold text-center text-primSky-950"
                                                    key={index}
                                                >
                                                    {item[0]}
                                                </p>
                                            </Link>
                                        );
                                    }
                                })}
                                <Button color="yellow" onClick={() => setIsSeeMore3(!isSeeMore3)}>
                                    {isSeeMore3 ? "اخفاء" : "عرض المزيد"}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div> */}
        </>
    );
}

export default SubjectCard;
