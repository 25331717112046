import "./scroll.css";
import { useEffect, useState } from "react";
import http from "../../../services/httpServices";
import { baseURL } from "../../../config";
import LoadingIcon from "../../ui/LoadingIcon";
import { Link } from "react-router-dom";

export default function Content({ teachers }) {
    const image = [
        "https://images.unsplash.com/photo-1607990283143-e81e7a2c9349?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2041&q=80",
        "https://images.unsplash.com/photo-1615538785945-6625ccdb4b25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",
        "https://images.unsplash.com/photo-1627907912793-28208bc69dd4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=600&q=60",
        "https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",
        "https://images.unsplash.com/photo-1534030347209-467a5b0ad3e6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",
        "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80",
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=600&q=60",
    ];

    const [isLoading, setIsLoading] = useState(true);
    return (
        <>
            <div className="lineContent py-10 px-5 flex items-center justify-between gap-[4rem]">
                {teachers.map((item, index) => (
                    <Link to={`/teacher/${item.id}`} key={index}>
                        <div
                            className="Teacher-mask rounded-2xl p-1 bg-blueMonq-200 dark:bg-blueMonq-800 smooth "
                            key={index}
                        >
                            {/* <img
                            className="image object-cover object-center"
                            src={`${baseURL}/${item.picture}`}
                            alt={`Teacher ${item.name}`}
                            /> */}
                            <img
                                src={`${baseURL}/${item.picture}`}
                                alt={`Teacher ${item.name}`}
                                onLoad={() => {
                                    setIsLoading(false);
                                }}
                                className={` ${
                                    isLoading ? "hidden " : undefined
                                }image object-cover object-center`}
                            />
                            {isLoading ? (
                                <div className="image object-cover object-center flex-center-both font-h1 font-w-bold">
                                    <div className="">
                                        <LoadingIcon className={"font-big text-blue-500"} />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </Link>
                ))}
                {/* {image.map((url, index) => (
                    <div
                        className="Teacher-mask  rounded-2xl outline outline-offset-4 outline-blueMonq-800 dark:outline-blueMonq-300 hover:outline-offset-0 smooth"
                        key={index}
                    >
                        <img className="image" src={url} alt="test" />
                    </div>
                ))} */}
            </div>
        </>
    );
}
