import "./scroll.css";
import Content from "./Content";
import ParallaxImage from "./Parallax";
import { useEffect, useState } from "react";
import http from "../../../services/httpServices";
import LoadingIcon from "../../ui/LoadingIcon";

const Teachers = () => {
    const [teachers, setTeachers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const fetchTeacher = async () => {
        let params = {
            limit: 100,
        };
        try {
            const { data: res } = await http.get("/api/teacher", {
                params,
            });
            setTeachers(res.results);
            setIsLoading(false);
        } catch (error) {
            //   console.log("err : ", error);
        }
    };
    useEffect(() => {
        fetchTeacher();
    }, []);

    return (
        <>
            {isLoading ? (
                <div className="flex-center-both">
                    <div className="border-2 rounded-md  border-blueMonq-300 dark:border-blueMonq-500 p-10 bg-blueMonq-100 dark:bg-opacity-5 smooth clr-text-primary flex space-x-2 space-x-reverse">
                        <LoadingIcon className="font-h1 text-blueMonq-500" />
                        <span>يتم الآن تحميل المدرسين ...</span>
                    </div>
                </div>
            ) : teachers.length < 1 ? (
                <div className="flex-center-both">
                    <div className="border-2 rounded-md border-earthYellow-300 dark:border-earthYellow-500 p-10 bg-earthYellow-100 dark:bg-opacity-5 smooth clr-text-primary">
                        سيتم اضافة مدرسين هنا قريبًا
                    </div>
                </div>
            ) : (
                <div className="TeacherScroll  relative w-full">
                    <div className="firstLine shadow-lg bg-primary-container smooth ">
                        <ParallaxImage baseVelocity={-5}>
                            <Content teachers={teachers} />
                        </ParallaxImage>
                    </div>
                </div>
            )}
        </>
    );
};

export default Teachers;
