import React, { useContext, useEffect, useState } from "react";
import InputField from "../../../components/form/elements/InputField";
import { handleInputChange } from "../../../services/formServices";
import { isMultiYear } from "../../../services/defaultSettings";
import { years } from "../../../services/yearSevices";
import Container from "../../../components/ui/Container";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import AuthContext from "../../../context/AuthContext";
import { isEmptyObject } from "jquery";
import SubjectCard from "../../../components/NewUi/subjectCard/SubjectCard";
import Heading from "../../../components/NewUi/heading/Heading";
import TeacherLine from "../../../components/NewUi/teacherHorizontal/TeacherLine";
import Card from "../../../components/NewUi/featureCard/Card";
import RetroCardSection from "../../../components/ui/RetroCardSection";
import LoadingIcon from "../../../components/ui/LoadingIcon";
import CenterIcon from "../../../components/ui/CenterIcon";
import Categorie from "./Categorie";
import Button from "../../../components/ui/Button";
import { isObjectEmpty } from "../../../utils/objects";

const initialState = {
    year: 3,
    subject_id: 0,
};
const useFetchedCourses = (data, user) => {
    const [courses, setCourses] = useState(false);
    const [isCoursesFetchingLoading, setIsCoursesFetchingLoading] = useState(true);
    const [isCoursesFetchingError, setIsCoursesFetchingError] = useState(false);

    const getCourses = async () => {
        setIsCoursesFetchingLoading(true);
        try {
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const { data: response } = await http.get(
                `/api/sellables/year/${!isObjectEmpty(user) ? user.year : data.year}`,
                config
            );
            setCourses(response);
            setIsCoursesFetchingLoading(false);
        } catch (error) {
            setIsCoursesFetchingError(true);
            setIsCoursesFetchingLoading(false);
        }
    };
    const handleFetchedCourses = () => {
        getCourses();
    };

    return {
        courses,
        isCoursesFetchingLoading,
        isCoursesFetchingError,
        getCourses,
        handleFetchedCourses,
    };
};

const useFetchedSubjectsByYear = (data, user) => {
    const [subjectLoading, setIsSubjectsLoading] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const getSubjects = async () => {
        setIsSubjectsLoading(true);
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);

        const { data: response } = await http.get(
            `/api/years/${!isObjectEmpty(user) ? user.year : data.year}/subjects/options`,
            config
        );
        setSubjects(response);
        setIsSubjectsLoading(false);
    };
    return { subjects, getSubjects, subjectLoading };
};
const SubjectsSection = () => {
    const [data, setData] = useState(initialState);
    const [seeMore, setSeeMore] = useState(false);
    const authUser = auth.getUser() || {};
    const { user } = useContext(AuthContext);
    const [filteredCourses, setFilteredCourses] = useState([]);

    const { subjects, getSubjects, subjectLoading } = useFetchedSubjectsByYear(data, authUser);
    const {
        courses,
        isCoursesFetchingLoading,
        isCoursesFetchingError,
        getCourses,
        handleFetchedCourses,
    } = useFetchedCourses(data, authUser);

    useEffect(() => {
        if (!courses) {
            getCourses();
        }
        if (courses) {
            setFilteredCourses(courses);
        }
    }, [courses]);

    useEffect(() => {
        getSubjects();
    }, [data.year]);

    useEffect(() => {
        if (!courses) return;

        if (courses && !data.subject_id && data.course_status === "all_courses") {
            setFilteredCourses(courses);
            return;
        }
        let filtered = [...courses];

        if (data.subject_id && data.subject_id != 0) {
            filtered = filtered.filter((course) => course.subject_id == data.subject_id);
        }

        filtered.sort((a, b) => {
            if (a.subscriptions_count === 1 && b.subscriptions_count !== 1) return -1;
            if (a.subscriptions_count !== 1 && b.subscriptions_count === 1) return 1;
            return 0;
        });
        setFilteredCourses(filtered);
    }, [data, courses]);

    useEffect(() => {
        getSubjects();
    }, []);

    useEffect(() => {
        if (!isEmptyObject(user) && user.year && user.year != data.year) {
            setData({
                ...data,
                year: user.year,
            });
        }
    }, [user]);

    return (
        <div className=" mb-20 mt-10  overflow-hidden" id="courses">
            {/* <ScrollingProvider>
                <SectionHead title={"محتوى المنصة"} /> 
            </ScrollingProvider> */}
            <div className="">
                <Container className="">
                    <Container className="flex flex-col space-y-5 items-center justify-center">
                        <div className="flex flex-col space-y-5 items-center">
                            <div className="flex-center-both">
                                <Heading title="كورساتنا ؟" subtitle="عايز مين ..؟" />
                            </div>
                            <p className="text-center text-gray-500 dark:text-gray-200 smooth w-full text-lg md:text-xl md:max-w-[500px]">
                                كل الي عليك تعمله عشان توصل للمحتوي الي انت عايزه تختار سنتك
                                الدراسية وتختار المادة العلمية المراد إظهار كورساتها .. مش هنسيبك
                                لوحدك
                            </p>
                        </div>
                    </Container>

                    <div>
                        <div className="flex items-center justify-between flex-col  space-y-5">
                            <div className=" px-10 py-3 rounded-md  border border-solid border-gray-400 smooth clr-text-primary">
                                {isMultiYear && (isEmptyObject(user) || !user.year) ? (
                                    <InputField
                                        onChange={handleInputChange}
                                        data={data}
                                        setData={setData}
                                        type="select"
                                        options={years}
                                        className="min-w-[250px]"
                                        id="year"
                                        placeholder="اختر الصف الدراسي"
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="flex-center-both gap-2 pt-2 flex-col bg-primSky-950/50 hover:bg-blueMonq-100 dark:hover:bg-blueMonq-800 dark:bg-slate-800/50 smooth shadow-md px-4 py-2 rounded-md">
                                <span className=" clr-text-primary smooth text-lg text-center font-semibold">
                                    أختار المادة الي بتحبها
                                </span>
                                {!subjectLoading ? (
                                    <InputField
                                        id="subject_id"
                                        type="radio"
                                        options={[
                                            {
                                                value: 0,
                                                text: "جميع المواد",
                                            },
                                            ...subjects,
                                        ]}
                                        onChange={handleInputChange}
                                        data={data}
                                        setData={setData}
                                    />
                                ) : (
                                    <div className="flex-center-both">
                                        <div className="border-2 rounded-md border-blue-300 dark:border-blue-500 p-3 bg-blue-100 dark:bg-opacity-5 smooth clr-text-primary flex space-x-2 space-x-reverse">
                                            <LoadingIcon className="font-h1 text-blue-500" />
                                            <span>يتم الآن تحميل المواد ...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
                <div>
                    {isCoursesFetchingError ? (
                        <div className="font-w-bold rounded-md bg-third-container clr-text-primary border border-secondary-container smooth p-5 flex-center-both space-x-3 space-x-reverse">
                            <CenterIcon icon="bxs:error" className="font-h1 text-rose-500" />
                            <span>حدث خطأ اثناء تحميل الكورسات</span>
                        </div>
                    ) : isCoursesFetchingLoading ? (
                        <div className="font-w-bold rounded-md bg-third-container clr-text-primary border border-secondary-container smooth p-5 flex-center-both space-x-3 space-x-reverse">
                            <span className="font-h1">
                                <LoadingIcon />
                            </span>
                            <span>يتم الآن تحميل الكورسات</span>
                        </div>
                    ) : (
                        <>
                            <Categorie
                                coursesList={
                                    seeMore ? filteredCourses : filteredCourses?.slice(0, 3)
                                }
                                noCoursesPlaceholder="لا يوجد كورسات متاحة"
                            />

                            {courses.length > 3 && (
                                <div className="flex justify-center mt-8">
                                    <Button
                                        onClick={() => setSeeMore((prev) => !prev)}
                                        color="rose"
                                    >
                                        {seeMore ? "عرض أقل" : "عرض المزيد من الكورسات"}
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SubjectsSection;
