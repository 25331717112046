import React from "react";
import "./heading.css";
import { ReactComponent as ElmonqezSlogan } from "../../../assets/monqez-assets/ELmonqez.svg";
function Heading({ title, subtitle }) {
    return (
        <div className="flex relative flex-col min-w-[250px] gap-5 justify-center items-center w-full max-w h-fit">
            <div className=" smooth min-w-[350px] clr-text-primary font-lama flex-col space-y-5 flex-center-both  px-5 py-5">
                <h1 className="text-4xl font-w-bold sm:text-6xl">{title} </h1>
                <span className="text-earthYellow-500 text-2xl">{subtitle}</span>
            </div>
            <ElmonqezSlogan className="absolute max-w-[250px] md:max-w-[400px] -z-10 left-7 md:-left-24 -top-24 md:-top-24" />
            {/* <div className="absolute -z-10 -top-5 -bottom-5 right-2 left-2  p-4 border border-solid border-blueMonq-500">
                <div className="border border-solid h-full border-blueMonq-500" />
            </div> */}
        </div>
    );
}

export default Heading;
