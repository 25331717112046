import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadingIcon from "../../components/ui/LoadingIcon";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import SectionableHeaderTitle from "../../components/ui/SectionableHeaderTitle";

const CourseLink = () => {
    const { id, section_id, course_link_id } = useParams();

    const getCourseLink = async () => {
        const token = auth.getToken();
        const config = auth.getAdminAuthConfig(token);
        try {
            const { data: repsone } = await http.get(
                `/api/sellables/course/${id}/sections/${section_id}/sectionables/${course_link_id}`,
                config
            );
            window.location.href = repsone.sectionable.url;
        } catch ({ response }) {}
    };

    useEffect(() => {
        getCourseLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="w-full max-w-4xl mx-auto rounded-2xl shadow-large overflow-hidden border border-secondary-container smooth clr-text-primary -mt-20 relative z-30 bg-primary-container">
                <SectionableHeaderTitle sectionable_id={course_link_id} />
                <div className="py-10 flex space-x-3 space-x-reverse flex-center-both font-h2">
                    <span className="flex-center-both font-big text-yellow-300">
                        <LoadingIcon />
                    </span>
                    <span>جاري التحميل</span>
                </div>
            </div>
        </div>
    );
};

export default CourseLink;
