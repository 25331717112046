import React, { useEffect, useState } from "react";
import Teachers from "../../components/NewUi/horizontalAnimation/Teachers";
import Heading from "../../components/NewUi/heading/Heading";
import Container from "../../components/ui/Container";
import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
import Star from "../../assets/Vip-imagery/star.png";
import OffsetButton from "../../components/ui/Buttons/OffsetButton";
import ConstructionDecore from "../../components/ui/ConstructionDecore";
import Pattern from "../../assets/monqez-assets/bgTeacherPattern.png";
import http from "../../services/httpServices";

const TeacherSection = () => {
    const token = auth.getToken();

    return (
        <section className="min-h-[80vh]  rounded-md mx-2 md:mx-10 relative my-10 overflow-hidden">
            <Container className="h-full  z-10 pt-32 md:pt-10 pb-10 md:pb-0  flex flex-col items-center justify-center space-y-5">
                <div className="flex relative flex-col items-center justify-center space-y-5">
                    <div className="flex flex-col items-center justify-center h-fit gap-10">
                        <div className="mx-auto">
                            <Heading title="مين في ضهرك ؟" subtitle="شوف مٌدرسينك ..؟" />
                        </div>
                        <p className="text-center w-full text-lg md:text-xl md:max-w-[600px]">
                            وصلناك لأفضل المدرسين أونلاين، مستعدين يساعدوك في فهم المنهج والإجابة
                            على كل أسئلتك بطريقة بسيطة وسهلة.
                        </p>
                    </div>
                    <OffsetButton
                        color="earthYellow"
                        element="Link"
                        ButtonType="Retro"
                        className="scale gap-4 text-earthYellow-950 font-w-bold "
                        icon={Star}
                        to={"/register"}
                    >
                        <span>استفد منهم</span>
                    </OffsetButton>
                    {/* <div className="-ml-20">
                        <ConstructionDecore className={""} isRight={false} />
                    </div> */}
                </div>
                <div className="relative bg-gray-50 dark:bg-gray-800 smooth">
                    <div
                        className="inset-0 w-full h-full z-0 absolute opacity-40 smooth"
                        style={{
                            backgroundImage: "url(" + Pattern + ")",
                            backgroundSize: "contain",
                            backgroundPosition: "center top",
                            backgroundRepeat: "repeat",
                        }}
                    ></div>
                    <Teachers />
                </div>
            </Container>
        </section>
    );
};

export default TeacherSection;
