export const getYearPlaceHolder = (year) => {
    const yearsPlaceHolders = {
        1: "الصف الأول الابتدائي",
        2: "الصف الثاني الابتدائي",
        3: "الصف الثالث الابتدائي",
        4: "الصف الرابع الابتدائي",
        5: "الصف الخامس الابتدائي",
        6: "الصف السادس الابتدائي",
        7: "الصف الأول الإعدادي",
    };
    return yearsPlaceHolders[year];
};
export const years = [
    { value: "1", text: getYearPlaceHolder(1) },
    { value: "2", text: getYearPlaceHolder(2) },
    { value: "3", text: getYearPlaceHolder(3) },
    { value: "4", text: getYearPlaceHolder(4) },
    { value: "5", text: getYearPlaceHolder(5) },
    { value: "6", text: getYearPlaceHolder(6) },
    { value: "7", text: getYearPlaceHolder(7) },
];
