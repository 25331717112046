import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import FacebookIcon from "../../assets/monqez-assets/facebook_icon.png";

import InstaIcon from "../../assets/monqez-assets/instagram_icon.png";
import telegramIcon from "../../assets/monqez-assets/telegram_icon.png";
import tiktokIcon from "../../assets/monqez-assets/tiktok_icon.png";
import youtubeIcon from "../../assets/monqez-assets/youtube_2504965.png";

import FooterLink from "./components/FooterLink";
import { Facebook, Youtube, Tiktok, instagram } from "./Icons";
import { useLocation } from "react-router-dom";
import { adminPath, teachersubadminPath } from "../../services/defaultSettings";
import CenterIcon from "../../components/ui/CenterIcon";
import { ReactComponent as Logo } from "../../assets/monqez-assets/FooterLogo.svg";
const Footer = () => {
    const location = useLocation();

    const [className, setClassName] = useState("");

    useEffect(() => {
        if (
            location.pathname.includes(adminPath) ||
            location.pathname.includes(teachersubadminPath)
        ) {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
    }, [location.pathname]);
    return (
        <div
            className={`footer bg-[#2C3A66] overflow-hidden relative py-20 clr-white flex-center-both flex-col space-y-6 w-full ${className} gap-6`}
        >
            <div className="h-auto flex justify-center">
                <Logo className="fill-blueMonq-300 w-[130px] md:w-[140px] " />
            </div>
            <div className="flex-center-both space-x-2 space-x-reverse z-10 text-2xl smooth rounded-md px-5 saturate-50">
                <p className="">متخصصة للطلاب الساعين للتفوق</p>
            </div>
            <div className="relative w-screen">
                <div className="relative z-10 flex items-center justify-center space-x-5 space-x-reverse gap-2 ">
                    <FooterLink
                        link="https://www.facebook.com/MathMahmoudElkholy?mibextid=LQQJ4d"
                        scale={false}
                        icon={<img src={FacebookIcon} alt="facebook" className="w-12 h-auto" />}
                    />
                    <FooterLink
                        link="https://t.me/MahmoudElkholyMath"
                        scale={false}
                        icon={<img src={telegramIcon} alt="tiktok" className="w-12 h-auto" />}
                    />
                    <FooterLink
                        link="https://www.tiktok.com/@math_mahmoudelkholy"
                        scale={false}
                        icon={<img src={tiktokIcon} alt="tiktok" className="w-12 h-auto" />}
                    />
                    <FooterLink
                        link="https://www.instagram.com/math_mahmoudelkholy"
                        scale={false}
                        icon={<img src={InstaIcon} alt="InstaIcon" className="w-12 h-auto" />}
                    />
                    <FooterLink
                        link="https://www.youtube.com/@Mahmoud.Elkholy"
                        scale={false}
                        icon={<img src={youtubeIcon} alt="youtubeIcon" className="w-12 h-auto" />}
                    />
                </div>
            </div>
            {/* <div className="h-1 bg-slate-800 rounded-md w-2/3 sm:w-1/3 "></div> */}
            <div className="Footer__wrapper-button mt-9 lg:mt-0">
                <div className="en textx text-center font-com space-x-2 opacity-90 px-5 flex flex-wrap flex-center-both">
                    <span className="font-w-bold space-x-1">
                        <span className="text-blueMonq-200">&#60;</span>
                        <span className="text-blueMonq-400">Developed By</span>
                        <span className="text-blueMonq-200">&#62;</span>
                    </span>
                    <span>
                        <a
                            href="https://www.facebook.com/Om4rS4Ieh/"
                            className="text-earthYellow-300 font-w-bold  hover-shadow smooth px-2 py-2 rounded-md"
                        >
                            Omar
                        </a>
                        <span>,</span>
                        <a
                            href="https://www.facebook.com/emad.sharf.16"
                            className="text-earthYellow-300 font-bold hover-shadow smooth px-3 py-1 rounded-md"
                        >
                            Emad
                        </a>
                    </span>
                    <span className="font-w-bold space-x-1">
                        <span className="text-blueMonq-200">&#60;</span>
                        <span className="text-blueMonq-400">
                            All Copy Rights Reserved @{new Date().getFullYear()}
                        </span>
                        <span className="text-blueMonq-200">&#62;</span>
                    </span>
                </div>
            </div>
            {/* <div className="text"></div> */}
        </div>
    );
};

export default Footer;
